import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import AvatarImage from '@shared/ui/display/AvatarImage';
import { BodyMediumSemiBold, BodySmall } from '@components/styled/Typography';
import TraderBadge from '@shared/ui/badges/TraderBadge';
import { ContainerColumn, ContainerRow } from '@components/styled';
import Button from '@shared/ui/buttons/Button';
import { ReactComponent as SwitchIcon } from '@icons/wolfkit-light/arrow-2-cw-light.svg';
import { useSwitchProfileMutation } from '@shared/api/profile';
import { useAppSelector } from '@app/store/Hooks';
import withTextEllipsis from '@shared/ui/tooltips/withTextEllipsis';
const Container = styled.div(props => (Object.assign(Object.assign({ display: 'inline-flex', flex: 1, flexDirection: 'row', alignItems: 'center', cursor: 'pointer', padding: `${props.theme.spacing_sizes.l}px`, gap: `${props.theme.spacing_sizes.xs * 2}px`, backgroundColor: `${props.theme.customColors.input.backgroundDisabled}`, borderRadius: `${props.theme.spacing_sizes.xs * 1.25}px` }, !props.isSelected && ({
    '&:hover': {
        backgroundColor: `${props.theme.palette.primary.main}`,
    },
})), props.isSelected && ({
    cursor: 'auto',
    border: '2px solid #6488F0',
    backgroundColor: '#F0F4FF',
    boxShadow: '0px 0px 0px 4px #C6D4F9',
}))));
const AvatarContainer = styled.div(props => ({
    width: `${props.theme.spacing_sizes.xs * 8}px`,
    height: `${props.theme.spacing_sizes.xs * 8}px`,
    minWidth: `${props.theme.spacing_sizes.xs * 8}px`,
    minHeight: `${props.theme.spacing_sizes.xs * 8}px`,
    borderRadius: '50%',
}));
const ProfileInfoContainer = styled(ContainerColumn)((props) => ({
    width: 'auto',
    height: 'auto',
    maxWidth: '75px',
    gap: props.theme.spacing_sizes.s,
}));
const Name = withTextEllipsis(styled(BodySmall)(props => ({
    fontSize: `${props.theme.spacing_sizes.xm}px`,
    fontWeight: 600,
})));
const ProfileTypeContainer = styled(ContainerColumn)(() => ({
    width: 'auto',
    height: '32px',
    justifyContent: 'center',
}));
const InfoContainer = styled(ContainerRow)((props) => ({
    width: '100%',
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const ActionsContainer = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
}));
const SwitchProfileButton = styled(Button)(() => ({
    color: '#FFF!important',
}));
const SwitchProfileBadgeButton = ({ profile, }) => {
    var _a;
    const { t } = useTranslation();
    const theme = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const { currentUserProfile } = useAppSelector((state) => state.profile);
    const canSwitchProfile = useAppSelector(state => { var _a; return !!((_a = state.user.user) === null || _a === void 0 ? void 0 : _a.canSwitchProfile); });
    const [switchProfile] = useSwitchProfileMutation();
    const isCurrentProfile = profile.id === (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id);
    const isTrader = profile.type === 'TRADER';
    const onMouseOver = () => {
        setIsHovered(true);
    };
    const onMouseOut = () => {
        setIsHovered(false);
    };
    const onSwitchProfileClick = () => {
        switchProfile({ profileId: profile.id });
    };
    const textColorPrimary = useMemo(() => (!isCurrentProfile && isHovered ? '#fff' : theme.palette.text.primary), [
        isCurrentProfile,
        isHovered,
        theme,
    ]);
    return (_jsxs(Container, { onMouseOut: onMouseOut, onMouseOver: onMouseOver, isSelected: isCurrentProfile, onClick: onSwitchProfileClick, children: [_jsx(AvatarContainer, { children: _jsx(AvatarImage, { publicName: profile.displayName, imageUrl: (_a = profile.profilePicture) === null || _a === void 0 ? void 0 : _a.avatar }) }), _jsxs(InfoContainer, { style: Object.assign({}, !isCurrentProfile && ({
                    justifyContent: 'space-between',
                })), children: [_jsxs(ProfileInfoContainer, { children: [_jsx(Name, { tooltipText: profile.displayName, color: textColorPrimary, lineHeight: '32px', children: profile.displayName }), _jsx(ProfileTypeContainer, { children: isTrader ?
                                    (_jsx(TraderBadge, { color: isHovered ? 'primary' : 'warning' })) :
                                    (_jsx(BodyMediumSemiBold, { color: textColorPrimary, lineHeight: '32px', children: t('profile.type.client') })) })] }), _jsxs(ActionsContainer, { children: [isCurrentProfile && (_jsx(BodyMediumSemiBold, { color: theme.palette.text.secondary, lineHeight: '32px', children: t('profile.current_profile') })), canSwitchProfile &&
                                !isCurrentProfile &&
                                isHovered && (_jsx(SwitchProfileButton, { variant: 'plain', size: 'small', startIcon: SwitchIcon, style: { visibility: isHovered ? 'visible' : 'hidden' }, children: isTrader ?
                                    t('settings.switch_to_trader', { ns: 'common' }) :
                                    t('settings.switch_to_client', { ns: 'common' }) }))] })] })] }));
};
export default SwitchProfileBadgeButton;
